/* src/pages/Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Aseguramos que el contenedor ocupe el 100% de la altura de la pantalla */
  background-color: #f5f5f5;
  margin: 0;  /* Eliminamos cualquier margen que pueda causar desplazamiento */
  overflow: hidden; /* Evitamos que cualquier contenido desborde */
}

.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;  /* Limita el ancho del formulario */
  text-align: center;
  box-sizing: border-box;  /* Asegura que padding y border estén incluidos en el tamaño */
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

.input-group {
  margin-bottom: 15px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.login-button:hover {
  background-color: #0056b3;
}

.forgot-password-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
}

.forgot-password-link:hover {
  text-decoration: underline;
}
