.table {
    width: 95%;
    border-collapse: collapse;
}

.table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
}

.table td {
    background-color: #fff;
    color: #333;
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
}