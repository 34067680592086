.company-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.company-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    width: 90%;
}

.company-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-edit {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    transition: background-color 0.3s ease;
}

.btn-edit:hover {
    background-color: #0056b3;
}

.company-details {
    margin-top: 20px;
}

.company-details h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #333;
}

.company-details p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
}

.company-details strong {
    color: #333;
}

.tree-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tree-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
}

.tree-card h3 {
    margin-top: 0;
}

.process-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.process-tree {
    width: 48%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.process-tree:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.process-tree button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.process-tree button:hover {
    background-color: #218838;
}

.process-tree p {
    font-size: 16px;
    color: #666;
}

.process-tree button {
    margin-left: 10px;
}

.process-tree button:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
}

.process-details {
    width: 48%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px 20px;
    margin-left: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}