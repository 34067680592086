.assistant-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 90%;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f0f0f0;
}

.conversation-history {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #e5ddd5;
}

.message {
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
}

.message-user {
    align-self: flex-end;
    background-color: #dcf8c6; /* Fondo verde similar a Whatsapp */
    color: #000;
    padding-left: 2vw;
    text-align: right;
    max-width: none;
    width: auto;
}

.message-assistant {
    align-self: flex-start;
    background-color: transparent;
    color: #000;
    margin-right: 100px;
    text-align: left;
}

.message-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;
}

.message-input button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #34b7f1;
    color: #fff;
    cursor: pointer;
    outline: none;
}

.message-input button:hover {
    background-color: #1a8cd8;
}

.error-message {
    color: #ff0000; /* Rojo */
    background-color: #ffe6e6; /* Fondo rojo claro */
    border: 1px solid #ff0000; /* Borde rojo */
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-weight: bold;
}

.loading-cursor {
    cursor: wait;
}