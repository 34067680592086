/* src/pages/Forms.css */

.form-container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.company-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

/* Aplicar el mismo estilo para input y select */
input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fff;
}

/* Dar fondo diferente a campos de solo lectura */
input[readonly] {
  background-color: #f2f2f2;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 10vw;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 10vw;
  margin-left: 1vw;
}

.btn-cancel:hover {
  background-color: #5a6268;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.form-footer {
  text-align: center;
  margin-top: 20px;
}

.back-link {
  color: #007bff;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.page-container {
  display: flex;
}

.children-container {
  width: 40%;
  padding: 20px;
  background-color: #f2f2f2;
  border-right: 1px solid #ccc;
}

.children-list {
  list-style-type: none;
  padding: 0;
}

.children-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
