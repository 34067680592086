@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";

/* src/App.css */
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* Cuando el sidebar está activo */
.app-with-sidebar {
  display: flex;
  flex-direction: row; /* Alinea el sidebar y el contenido en una fila */
}

.sidebar {
  width: 250px;
  background-color: #4D4D4D;
  color: white;
  padding-top: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.sidebar h2 {
  color: white;
  padding: 15px;
}

/* Estilos para la lista del menú */
.sidebar ul {
  list-style: none;  /* Elimina los puntos de lista */
  padding: 0;
}

.sidebar ul li {
  padding: 15px;
}

.sidebar ul li a, .sidebar ul li button {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
}

.sidebar ul li button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  font-size: 16px;
}

.sidebar-logo {
  width: 80px; 
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-left: 15px;
}

.main-content {
  flex-grow: 1;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Ocupa toda la pantalla */
  font-size: 24px;
  color: #333;
  background-color: #f5f5f5;
}

/* Aplicar margen solo cuando hay sidebar */
.app-with-sidebar .main-content {
  padding-left: 20px;
  margin-left: 250px;  /* Deja espacio para el sidebar */
}

.error-message {
  border: 1px solid red;
  background-color: #f8d7da;
  padding: 10px;
  color: #721c24;
}

.treeview {
  width: 100%;
  height: 100%;
  overflow: auto;
}